<template>
  <data-list-view-wrapper>
    <product-category-sidebar
      v-if="$gate.can(['create_product_categories', 'edit_product_categories'])"
      :active="addNewDataSidebar"
      :data="sidebarData"
      @close="toggleDataSidebar"
    />

    <vx-table
      ref="table"
      v-model="selected"
      multiple
      pagination
      :max-items="itemsPerPage"
      search
      :data="productCategories"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <action-dropdown>
            <action-dropdown-item
              v-if="$gate.can('delete_product_categories')"
              icon="TrashIcon"
              title="Delete"
              @click="deleteSelected"
            />
          </action-dropdown>

          <button-create
            v-if="$gate.can('create_product_categories')"
            title="Add category"
            @click="addNewData"
          />
        </div>

        <items-per-page
          :page="currentPage"
          :per-page="itemsPerPage"
          :total="productCategories.length"
          :queried-items="queriedItems"
          @change="changeItemsPerPage"
        />
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="$gate.can('edit_product_categories')"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                v-if="$gate.can('delete_product_categories')"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ProductCategoryAction } from '@/store/actionTypes';

import confirmationMixin from '@/mixins/confirmationMixin';
import dataListMixin from '@/mixins/dataListMixin';
import moduleProductCategory from '@/store/product-categories/moduleProductCategory';

import ActionDropdown from '@/views/components/actions/ActionDropdown';
import ActionDropdownItem from '@/views/components/actions/ActionDropdownItem';
import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import ItemsPerPage from '@/views/components/ItemsPerPage';
import ProductCategorySidebar from '@/views/sidebars/ProductCategorySidebar';
import VxTable from '@/components/vx-table/vxTable';

export default {
  components: {
    ActionDropdown,
    ActionDropdownItem,
    ButtonCreate,
    DataListViewWrapper,
    ItemsPerPage,
    ProductCategorySidebar,
    VxTable,
  },
  mixins: [confirmationMixin, dataListMixin],
  computed: {
    ...mapState('product-category', {
      productCategories: ({ productCategories }) => productCategories,
    }),

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.productCategories.length;
    },
  },
  methods: {
    ...mapActions('product-category', [
      ProductCategoryAction.fetch,
      ProductCategoryAction.remove,
    ]),

    registerStoreModules() {
      if (!moduleProductCategory.isRegistered) {
        this.$store.registerModule('product-category', moduleProductCategory);
        moduleProductCategory.isRegistered = true;
      }
    },
    addNewData() {
      if (!this.$gate.can('create_product_categories')) {
        return;
      }

      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    async deleteData(id) {
      if (!this.$gate.can('delete_product_categories')) {
        return;
      }

      this.$vs.loading();

      try {
        await this.removeProductCategory(id);

        this.notifySuccess(
          'Product category deleted',
          'The product category has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a category.');
      }

      this.$vs.loading.close();
    },
    editData(data) {
      if (!this.$gate.can('edit_product_categories')) {
        return;
      }

      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    deleteSelected() {
      const ids = this.selected.map((item) => item.id);

      if (ids.length) {
        this.confirmDeleteAll(ids);
      }
    },
    confirmDeleteAll(ids) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete ${ids.length} selected categories?`,
        accept: () => this.deleteAll(ids),
        acceptText: 'Delete',
      });
    },
    async deleteAll(ids) {
      this.$vs.loading();

      try {
        await Promise.all(ids.map((id) => this.removeProductCategory(id)));

        this.notifySuccess(
          'Selected categories deleted',
          'Selected categories has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting categories.');
      }

      this.$vs.loading.close();
      this.selected = [];
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchProductCategories();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
